import { ConfigCatProvider, User } from 'configcat-react'
import React from 'react'

import { FloraProvider } from '@grupoboticario/flora-react'
import { MonitoringClient } from '@grupoboticario/vdi-mfe-utils'
import { authService } from '@vdi/auth-service'

import { AttendanceProvider } from '@register-attendance/providers'
import { ApiProvider, makeApiClient } from '@shared/api'
import { env } from '@shared/env'
import { initI18n } from '@shared/i18n'
import { AppProvider, DrawerProvider, ModalProvider } from '@shared/providers'

import { App } from './app'

const monitor = MonitoringClient.createGlobalAgent()
const api = makeApiClient(monitor)
const user = new User(authService.userData.geraId)
initI18n(monitor)

const RootComponent: React.FC = () => (
  <React.StrictMode>
    <FloraProvider>
      <ConfigCatProvider sdkKey={env.CONFIG_CAT_SDK_KEY} options={{ defaultUser: user }}>
        <ApiProvider api={api}>
          <AttendanceProvider>
            <ModalProvider>
              <DrawerProvider>
                <AppProvider>
                  <App />
                </AppProvider>
              </DrawerProvider>
            </ModalProvider>
          </AttendanceProvider>
        </ApiProvider>
      </ConfigCatProvider>
    </FloraProvider>
  </React.StrictMode>
)

export { monitor, RootComponent }
