import { FloraButton, styled, TabPanel as TabPanelFlora } from '@grupoboticario/flora-react'

import { scrollbar } from '@shared/components'

const OrderDetailPaymentContainer = styled('div', {
  position: 'relative',
  width: '355px',
  borderRadius: '$medium',
  background: '$backgroundPrimary',
  padding: '22px 19px $4',
  gap: '$4',
})

const PaymentInfoContainer = styled('div', {
  marginBottom: '$4',

  '*': {
    margin: 0,
  },

  '& > p': {
    marginLeft: '$6',
  },
})

const PaymentInfoLabel = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  marginBottom: '$2',
})

const PaymentTagsContainer = styled('div', {
  position: 'absolute',
  top: '22px',
  right: '25px',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
  gap: '$2',
})

const Carousel = styled('ul', {
  display: 'flex',
  overflow: 'auto',
  flexFlow: 'row nowrap',
  width: '100%',
  scrollSnapType: 'x mandatory',
  scrollbarWidth: 'none',
  paddingLeft: 0,
  paddingBottom: '$4',
  margin: 0,
  transition: 'scrollbar-width 0.2s ease-out',

  '&:hover': {
    ...scrollbar,
  },
})

const CarouselItem = styled('li', {
  display: 'flex',
  maxWidth: '344px',
  flex: 'none',
  scrollSnapAlign: 'center',

  '&:first-of-type': {
    marginLeft: '$5',
  },

  '&:not(:last-of-type)': {
    marginRight: '$2',
  },

  '&:last-of-type': {
    marginRight: '$5',
  },
})

const TabPanel = styled(TabPanelFlora, {
  paddingLeft: 0,
  paddingRight: 0,
})

const DownloadButton = styled(FloraButton, {
  display: 'flex',
  width: '272px !important',
  marginLeft: 'auto',
  marginRight: 'auto',

  '&[aria-disabled="true"]': {
    boxShadow: 'none',
  },
})

const Identifier = styled('p', {
  fontSize: '$bodySmall',
  fontWeight: '$medium',
  lineHeight: '$normal',
  marginBottom: '$3',
})

export {
  Carousel,
  CarouselItem,
  DownloadButton,
  Identifier,
  OrderDetailPaymentContainer,
  PaymentInfoContainer,
  PaymentInfoLabel,
  PaymentTagsContainer,
  TabPanel,
}
