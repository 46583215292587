import { Flex, styled } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  height: '100%',
  paddingLeft: '$4',
  paddingRight: '$4',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',

  '@mobile': {
    paddingLeft: '$8',
    paddingRight: '$8',
    justifyContent: 'initial',
  },
})

export { Container }
