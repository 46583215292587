import React, { useMemo } from 'react'

import { Flex } from '@grupoboticario/flora-react'

import { CanCopyButton, DataSection } from '@shared/components'
import { TabName, useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { useOrderDetailContext } from '../order-detail.provider'
import { ProductList } from './product-list'

const Products: React.FC = () => {
  const { t } = useTranslation('orders', { keyPrefix: 'detail.products' })
  const { data, filled, loading, error, refetch } = useOrderDetailContext()

  const events = useEvents()
  const products = data?.items

  const clipboardContent = useMemo(() => {
    if (!products) return ''
    let clipboardString = `${t('orderComposition')}\n`

    for (let index = 0; index < products.length; index++) {
      const quantity = `[Qtd: ${products[index].quantity}]`
      const renderPrice =
        typeof products[index].totalValue === 'number'
          ? `(${t('price', { price: products[index].totalValue as number })})`
          : ''
      const renderBreakLine = products.length === index + 1 ? '' : '\n'

      clipboardString += `${index + 1}. ${
        products[index].name
      } ${quantity}${renderPrice}${renderBreakLine}`
    }

    return clipboardString
  }, [products, t])

  return (
    <DataSection
      data={data}
      error={error}
      filled={filled}
      loading={loading}
      onRetry={refetch}
      fallbackHeight={250}
      title={
        <Flex as="span">
          {t('orderComposition')}
          <CanCopyButton
            clipboardText={clipboardContent}
            sectionName="detalhe-pedido"
            tabName={TabName.profile}
            textName={t('orderComposition')}
            onCopy={() => events.clickOrderCopyComposition(data!.number)}
          />
        </Flex>
      }
      render={(detail) => <ProductList title={t('purchaseProducts')} products={detail.items} />}
    />
  )
}

export { Products }
