import React from 'react'

import { Flex, Text, TextProps } from '@grupoboticario/flora-react'

interface CardDataProps {
  text: string
  value: string
  size?: TextProps['size']
}

const CardData: React.FC<CardDataProps> = ({ value, text, size }) => {
  const textSize = size ?? 'exceptionsAuxiliarMedium'

  return (
    <Flex gap="$2">
      <Text css={{ flex: 1 }} size={textSize} weight="medium" color="$nonInteractiveAuxiliar">
        {text}
      </Text>

      <Text size={textSize} weight="medium" color="$nonInteractiveAuxiliar">
        {value}
      </Text>
    </Flex>
  )
}

export { CardData }
