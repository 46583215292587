import { Flex, Text } from '@grupoboticario/flora-react'
import { CircleDashIcon, TagOutlineIcon } from '@grupoboticario/flora-react-icons'

import { AccordionItem } from '@shared/components'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { ContainerText } from './promotional-data-item.styles'

interface PromotionalDataItemProps {
  name: string
  promotions: string[]
}

const PromotionalDataItem = ({ name, promotions }: PromotionalDataItemProps): JSX.Element => {
  const { t } = useTranslation('promotionalData')
  const events = useEvents()

  return (
    <AccordionItem
      title={t(`campaignsNames.${name}`)}
      onClick={() => events.clickDropdownPromotion(name)}
      content={
        <Flex direction="row" align="center" justify="space-between" wrap="wrap" gap="$6">
          {!promotions.length && (
            <ContainerText align="center" gap="$2" justify="initial">
              <CircleDashIcon color="$statusAlertNonInteractiveEmphasis" size="16px" />
              <Text
                as="p"
                size="bodyMediumShort"
                color="$statusAlertNonInteractiveEmphasis"
                weight="medium"
              >
                {t('doNotHavePromotions')}
              </Text>
            </ContainerText>
          )}

          {promotions.map((promotion: string, index: number) => (
            <ContainerText key={index} gap="$2" justify="initial">
              <TagOutlineIcon size="16px" color="$9" />
              <Text as="p" size="bodyMediumShort" weight="medium">
                {promotion}
              </Text>
            </ContainerText>
          ))}
        </Flex>
      }
    />
  )
}

export { PromotionalDataItem }
