import { Flex } from '@grupoboticario/flora-react-flex'
import { Image } from '@grupoboticario/flora-react-image'
import { FloraTypography } from '@grupoboticario/flora-react-typography'
import { DataBlock } from '@shared/components'

import { useTranslation } from '@shared/i18n'

interface CurrentTierProps {
  value: string
  nextTierValue?: string
  imageUrl?: string
}

const CurrentTier: React.FC<CurrentTierProps> = (props) => {
  const { t } = useTranslation(['fidelity', 'resellerFidelity'])

  const { value, nextTierValue, imageUrl } = props

  return (
    <DataBlock
      renderContentOnly
      content={
        <Flex
          id="current-tier"
          align="center"
          gap="$2"
          role="listitem"
          aria-label={t('resellerFidelity:currentTier')}
        >
          {imageUrl && (
            <Image src={imageUrl} size="52px" alt={t('fidelityIconAlt', { tier: value })} />
          )}
          <Flex direction="column">
            <FloraTypography
              fontSize="exceptionsRestricted"
              css={{
                color: '$nonInteractiveAuxiliar',
              }}
            >
              {t('resellerFidelity:currentTier').toUpperCase()}
            </FloraTypography>
            <FloraTypography
              fontSize="bodyLargeStandard"
              fontWeight="medium"
              css={{
                color: '$nonInteractivePredominant',
              }}
            >
              {value}
            </FloraTypography>
            <FloraTypography
              fontSize="exceptionsAuxiliar"
              css={{
                color: '$nonInteractiveAuxiliar',
              }}
            >
              {t('resellerFidelity:nextTierDisplay')}
              <b style={{ fontWeight: '700' }}>{nextTierValue}</b>
            </FloraTypography>
          </Flex>
        </Flex>
      }
    />
  )
}

export { CurrentTier, CurrentTierProps }
