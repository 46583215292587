import { Flex, styled, Text } from '@grupoboticario/flora-react'
import { scrollbar } from '@shared/components'

const AttendanceListWrapper = styled(Flex, {
  height: 'calc(100vh - 156px)',
  margin: 0,
  overflowY: 'auto',
  padding: '$1 $2',
  ...scrollbar,
})

const AttendanceListTitle = styled(Text, {
  color: '$nonInteractiveAuxiliar',
  fontSize: '$auxiliar',
  fontWeight: '$medium',
  lineHeight: '$short',
  textTransform: 'uppercase',
  padding: '0 $2 $2 $2',
})

const AttendanceStatesWrapper = styled('div', {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
})

export { AttendanceListTitle, AttendanceListWrapper, AttendanceStatesWrapper }
