import { Flex, styled, Text } from '@grupoboticario/flora-react'

const Container = styled(Flex, {
  position: 'relative',
  width: '100%',

  '> div': {
    marginRight: '$2',
  },

  '&:first-of-type': {
    marginTop: '$2',
  },

  variants: {
    justify: {
      spaceBetween: {
        justifyContent: 'space-between',
      },
      initial: {
        justifyContent: 'initial',
      },
    },
  },
})

const LastAccess = styled(Text, {
  position: 'absolute',
  right: 0,
  color: '$nonInteractiveAuxiliar',
  fontSize: '0.6875rem',
  fontWeight: '$medium',
  lineHeight: '$short',
  maxWidth: 90,
  textAlign: 'right',

  '@mobile': {
    lineHeight: '$2xl',
    maxWidth: 'initial',
  },
})

export { Container, LastAccess }
