import { type OrderStatusTypes } from '../order-history.types'

interface OrderDetailDTO {
  cycle: string
  date: string
  items: OrderProduct[]
  payments: OrderPayment[]
  number: string
  status: OrderStatusTypes
  statusDescription: string
  totalValue: number
}

interface OrderProduct {
  code: string
  name: string
  quantity: number
  totalValue?: number
}

interface OrderPayment {
  type: string
  value: number
  dueDate: string
  fileLink: string
  identifier: string
  installments: number
  titles: Array<{
    paymentStatus: OrderDetailPaymentStatus
    titleStatus: OrderDetailTitleStatus
    titleStatusDescription: string
    paymentPlan: { name: string }
  }>
}

enum OrderDetailPaymentStatus {
  PENDENTE = 'PENDENTE',
  CANCELADO = 'CANCELADO',
  REALIZADO = 'REALIZADO',
}

enum OrderDetailTitleStatus {
  VENCIDO = 'VENCIDO',
  NAO_VENCIDO = 'NAO_VENCIDO',
}

type OrderDetailListItemProps = OrderProduct

export {
  OrderDetailPaymentStatus,
  OrderDetailTitleStatus,
  type OrderDetailDTO,
  type OrderDetailListItemProps,
  type OrderPayment,
}
