import { Fragment } from 'react'

import { Checkbox, Flex, Text } from '@grupoboticario/flora-react'
import { toCEP } from '@grupoboticario/vdi-mfe-utils'

import { type ResellerAddress } from '@reseller-data/providers'
import { DataBlock, DataSection, GridMobile } from '@shared/components'
import { useTranslation } from '@shared/i18n'

interface AddressTypeProps {
  address?: ResellerAddress
  checked?: boolean
  checkbox?: boolean
  title?: string
  heading: string
}

const AddressType = ({
  address,
  heading,
  checked,
  checkbox,
  title,
}: AddressTypeProps): JSX.Element => {
  const { t } = useTranslation('addressType')

  return (
    <DataSection
      filled
      title={title}
      data={address}
      render={(data) => (
        <Fragment>
          <DataBlock
            heading={heading}
            title={t('dataBlock.zipCode')}
            description={{ text: toCEP(data.zipCode) }}
          />

          <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
            <DataBlock
              title={t('dataBlock.street')}
              description={{ text: data.street, capitalize: true }}
            />

            <DataBlock title={t('dataBlock.number')} description={{ text: data.number }} />
          </GridMobile>

          <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
            <DataBlock
              title={t('dataBlock.neighborhood')}
              description={{ text: data.neighborhood, capitalize: true }}
            />

            <DataBlock
              title={t('dataBlock.complement')}
              description={{ text: data.complement, capitalize: true }}
            />
          </GridMobile>

          <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
            <DataBlock
              title={t('dataBlock.city')}
              description={{ text: data.city, capitalize: true }}
            />

            <DataBlock title={t('dataBlock.state')} description={{ text: data.state }} />
          </GridMobile>

          {checkbox && (
            <Flex align="center" gap="$2" css={{ margin: '$2 $4 $4 $4' }}>
              <Checkbox checked={checked} disabled id="chechbox-same-address" />

              <Text as="label" htmlFor="chechbox-same-address" color="$nonInteractiveAuxiliar">
                {t('dataBlock.useDeliveryAddress')}
              </Text>
            </Flex>
          )}
        </Fragment>
      )}
    />
  )
}

export { AddressType }
