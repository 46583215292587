import { TagProps } from '@grupoboticario/flora-react'
import { toCurrency, toLocaleDate } from '@grupoboticario/vdi-mfe-utils'
import { useEvents } from '@shared/events'
import { downloadFile } from '@shared/utils'

import {
  OrderDetailPaymentStatus,
  type OrderDetailTitleStatus,
  type OrderPayment,
} from '../order-detail.types'

interface UseOrderPayments {
  canceled: OrderPayment[]
  paidouts: OrderPayment[]
  pendings: OrderPayment[]
  installments: number
  hasPending: boolean
  hasPaidout: boolean
  hasCanceled: boolean
}

const useOrderPayments = (payments: OrderPayment[]): UseOrderPayments => {
  const paidouts = payments.filter(
    (p) => p.titles[0].paymentStatus === OrderDetailPaymentStatus.REALIZADO,
  )

  const pendings = payments.filter(
    (p) => p.titles[0].paymentStatus === OrderDetailPaymentStatus.PENDENTE,
  )

  const canceled = payments.filter(
    (p) => p.titles[0].paymentStatus === OrderDetailPaymentStatus.CANCELADO,
  )

  return {
    canceled,
    paidouts,
    pendings,
    installments: payments.length,
    hasPending: pendings.length > 0,
    hasPaidout: paidouts.length > 0,
    hasCanceled: canceled.length > 0,
  }
}

interface UseOrderPayment {
  getMethod: () => string
  getCurrentInstalment: () => string
  getValue: () => string
  getDueDate: () => string
  getTags: () => PaymentTag[]
  downloadBillet: () => void
  isPaidout: boolean
  isBillet: boolean
}

interface PaymentTag {
  color: TagProps['variant']
  text: string
}

const useOrderPayment = (payment: OrderPayment, installments: number): UseOrderPayment => {
  const events = useEvents()
  const title = payment.titles[0]

  const getDueDate = (): string => toLocaleDate(payment.dueDate)
  const getValue = (): string => toCurrency(payment.value)
  const getMethod = (): string => payment.titles[0]?.paymentPlan.name
  const getCurrentInstalment = (): string =>
    `${payment.type} ${payment.installments} de ${installments}`

  const getTags = (): PaymentTag[] => {
    const tags: PaymentTag[] = []

    if (
      title.paymentStatus === OrderDetailPaymentStatus.REALIZADO ||
      title.paymentStatus === OrderDetailPaymentStatus.CANCELADO
    ) {
      tags.push(TAGS[title.paymentStatus])
    } else if (title.paymentStatus === OrderDetailPaymentStatus.PENDENTE) {
      tags.push(TAGS[title.paymentStatus], TAGS[title.titleStatus])
    }

    return tags
  }

  const downloadBillet = (): void => {
    events.clickDownloadTitle(payment.identifier)
    downloadFile(payment.fileLink)
  }

  return {
    isPaidout: title.paymentStatus === OrderDetailPaymentStatus.CANCELADO,
    isBillet: payment.type === 'Boleto',
    downloadBillet,
    getDueDate,
    getMethod,
    getCurrentInstalment,
    getValue,
    getTags,
  }
}

const TAGS: Record<OrderDetailPaymentStatus | OrderDetailTitleStatus, PaymentTag> = {
  PENDENTE: {
    color: 'alert',
    text: 'paymentOpened',
  },
  CANCELADO: {
    color: 'alert',
    text: 'paymentCanceled',
  },
  REALIZADO: {
    color: 'success',
    text: 'paymentPaidout',
  },
  VENCIDO: {
    color: 'error',
    text: 'paymentDue',
  },
  NAO_VENCIDO: {
    color: 'assorted9',
    text: 'paymentNotDue',
  },
}

export { useOrderPayments, useOrderPayment, type PaymentTag }
