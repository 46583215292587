import React, { createContext, useCallback, type PropsWithChildren } from 'react'

import { useApiData, type UseApiDataOutput } from '@shared/api'

import { type ResellerDataDTO, type ResellerDataViewDTO } from './reseller-data-provider.types'

type ResellerDataContextProps = UseApiDataOutput<ResellerDataDTO>
type ResellerDataViewContextProps = UseApiDataOutput<ResellerDataViewDTO>

const ResellerDataContext = createContext<ResellerDataContextProps | null>(null)
const ResellerDataViewContext = createContext<ResellerDataViewContextProps | null>(null)

interface ResellerDataProviderProps extends PropsWithChildren {
  resellerId: string
  isV2: boolean
}

const ResellerDataProvider: React.FC<ResellerDataProviderProps> = ({
  resellerId,
  children,
  isV2,
}) => {
  const getApiParams = useCallback(() => ({ resellerId }), [resellerId])
  const dataSourceName = isV2 ? 'getResellerComplementDataV2' : 'getResellerComplementData'

  const data = useApiData<ResellerDataDTO>({
    dataSource: 'getResellerGeneralData',
    pollingOnError: true,
    getApiParams,
  })

  const view = useApiData<ResellerDataViewDTO>({
    dataSource: dataSourceName,
    pollingOnError: true,
    getApiParams,
  })

  return (
    <ResellerDataContext.Provider value={data}>
      <ResellerDataViewContext.Provider value={view}>{children}</ResellerDataViewContext.Provider>
    </ResellerDataContext.Provider>
  )
}

export {
  ResellerDataContext,
  ResellerDataProvider,
  ResellerDataViewContext,
  type ResellerDataContextProps,
  type ResellerDataViewContextProps,
}
