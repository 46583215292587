import { AccordionButton, Flex, styled, Text } from '@grupoboticario/flora-react'

const Header = styled(AccordionButton, {
  display: 'grid',
  gridTemplateColumns: 'calc(100% - 32px) $4',
  gridColumnGap: '$4',
  width: '100%',

  '> span': {
    display: 'block !important',
    width: '100%',
  },
})

const HeaderContainer = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  width: '100%',

  '@desktop': {
    display: 'grid',
    gridTemplateColumns: '160px auto',
    gridColumnGap: '$4',
  },

  '@fullscreen': {
    gridTemplateColumns: '180px auto',
  },
})

const StyledAccordionContent = styled('div', {
  backgroundColor: '$backgroundSecondary',
  padding: '$4',

  '@mobile': {
    padding: '$6 $4',
  },
})

const Title = styled(Text, {
  color: '$nonInteractivePredominant',
  fontSize: '$lg',
  fontWeight: '$medium',
  lineHeight: '$base',

  '@tablet': {
    minWidth: '120px',
    display: 'inline-block',
  },
})

export { Header, HeaderContainer, StyledAccordionContent, Title }
