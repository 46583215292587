import { styled, Text } from '@grupoboticario/flora-react'

const Avatar = styled(Text, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$12',
  height: '$12',
  backgroundColor: '$nonInteractiveAltAuxiliar',
  borderRadius: '50%',
  color: '$nonInteractiveAltPredominant',
  fontSize: '$subtitleDesk',
  fontWeight: '$medium',
  lineHeight: '$taller',
  pointerEvents: 'none',
})

const AvatarContainer = styled('div', {
  position: 'absolute',
  top: 'calc(50% - 48px / 2)',
  left: '$8',
  width: '$12 !important',
  height: '$12',
})

const Container = styled('div', {
  position: 'relative',
  width: '100%',
  backgroundColor: '$backgroundPrimary',
  borderRadius: '10px',
  paddingTop: '$4',
  paddingBottom: '$4',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  '> div': {
    width: '100%',
  },

  variants: {
    hasAvatar: {
      true: {
        paddingLeft: '100px',
      },
      false: {
        paddingLeft: '$4',
        '@mobile': {
          paddingLeft: '$8',
        },
      },
    },
    hasIcon: {
      true: {
        paddingRight: '70px',
      },
      false: {
        paddingRight: '$4',
        '@mobile': {
          paddingRight: '$8',
        },
      },
    },
  },
})

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

const Heading = styled(Text, {
  display: 'block',
  width: '100%',
  borderBottomWidth: '$hairline',
  borderBlockEndStyle: 'solid',
  borderBottomColor: '$nonInteractiveOutline',
  color: '$nonInteractiveAuxiliar',
  fontSize: '$auxiliar',
  fontWeight: '$medium',
  textTransform: 'uppercase',
  paddingBottom: '$2',
  marginBottom: '$4',
})

const IconContainer = styled('span', {
  position: 'absolute',
  top: '$4',
  right: '$4',
  display: 'inline-flex',
  width: '$10',
  height: '$10',
  alignItems: 'center',
  justifyContent: 'center',
})

export { Avatar, AvatarContainer, Container, Content, Heading, IconContainer }
