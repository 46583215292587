import { Text } from '@grupoboticario/flora-react'
import { toLocaleDate } from '@grupoboticario/vdi-mfe-utils'

import { useTranslation } from '@shared/i18n'
import { ResellerAppStatus } from '../reseller-app-status'

import { Container, LastAccess } from './reseller-app.styles'
import { type ResellerAppProps } from './reseller-app.types'

const ResellerApp = ({ description, enabled, appLastAccess }: ResellerAppProps): JSX.Element => {
  const { t } = useTranslation('resellerApp')

  const renderDate = appLastAccess && (
    <LastAccess>
      {t('appLastAccessDate', { appLastAccess: toLocaleDate(appLastAccess) })}
    </LastAccess>
  )

  return (
    <Container align="center">
      <ResellerAppStatus enabled={enabled} />

      <Text as="p" color="$nonInteractivePredominant" size="bodyLargeStandardMedium">
        {description}
        {renderDate}
      </Text>
    </Container>
  )
}

export { ResellerApp }
