import { DataBlockDescription } from './data-block-description'
import { DataBlockTitle } from './data-block-title'

import { DataBlockCanCopy, DataBlockProps } from './data-block.types'

import {
  Avatar,
  AvatarContainer,
  Container,
  Content,
  Heading,
  IconContainer,
} from './data-block.styles'

const DataBlock = (props: DataBlockProps): JSX.Element => {
  const {
    textToCopy,
    avatar,
    canCopy,
    content,
    description,
    heading,
    icon,
    renderContentOnly,
    tooltip,
    title,
    sectionName,
    tabName,
    ...others
  } = props as DataBlockCanCopy

  if (renderContentOnly) {
    return <Container>{content}</Container>
  }

  const renderIcon = icon && <IconContainer data-testid="data-block:icon">{icon}</IconContainer>

  const renderAvatar = avatar && (
    <AvatarContainer>
      <Avatar>{avatar}</Avatar>
    </AvatarContainer>
  )

  const renderHeading = heading && <Heading as="h3">{heading}</Heading>

  const renderDescription = description && (
    <DataBlockDescription
      {...description}
      textName={title}
      textToCopy={textToCopy}
      sectionName={sectionName}
      tabName={tabName}
      avatar={Boolean(avatar)}
      canCopy={canCopy}
    />
  )

  return (
    <Container {...others} hasIcon={Boolean(icon)} hasAvatar={Boolean(avatar)}>
      {renderAvatar}

      <Content>
        {renderHeading}
        <DataBlockTitle tooltip={tooltip} title={title} />
        {renderDescription}
        {content}
        {renderIcon}
      </Content>
    </Container>
  )
}

export { DataBlock, type DataBlockProps }
