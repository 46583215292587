import React, { useCallback } from 'react'

import { useApiData } from '@shared/api'
import { DataSection } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { OrderProduct } from '../../order-detail.types'
import { ProductList } from '../product-list'
import { OrderGift } from './gifts.types'

interface GiftsProps {
  resellerId: string
  orderNumber: string
}

const Gifts: React.FC<GiftsProps> = ({ resellerId, orderNumber }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'detail.products' })
  const getApiParams = useCallback(() => ({ resellerId, orderNumber }), [resellerId, orderNumber])

  const { data, filled, error, loading, refetch, unavailable } = useApiData<OrderGift[]>({
    dataSource: 'getOrderGifts',
    getApiParams,
  })

  return (
    <DataSection
      data={data}
      error={error}
      filled={filled}
      loading={loading}
      onRetry={refetch}
      unavailable={unavailable}
      fallbackHeight={250}
      render={(gifts) => <ProductList title={t('gifts')} products={fromGiftToProduct(gifts)} />}
    />
  )
}

const fromGiftToProduct = (gifts: OrderGift[]): OrderProduct[] =>
  gifts.map((g) => ({
    code: String(g.productCode),
    name: g.productName,
    quantity: g.quantity,
    totalValue: 0,
  }))

export { Gifts }
