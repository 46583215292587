import { FloraButton } from '@grupoboticario/flora-react-button'
import { Flex } from '@grupoboticario/flora-react-flex'
import { LinkIcon, QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons'
import { FloraTypography } from '@grupoboticario/flora-react-typography'
import { DataBlock } from '@shared/components'
import { useTranslation } from '@shared/i18n'

const MCM_FAQ_URL = 'https://supervisor-static.boticario.com.br/mcm/FAQ-MEU%20CLUBE%20MULTI.pdf'

const HelpPanel: React.FC = () => {
  const { t } = useTranslation('resellerFidelity')

  const redirectToHelp = (): void => {
    window.open(MCM_FAQ_URL, '_blank', 'noopener')
  }

  return (
    <DataBlock
      renderContentOnly
      content={
        <Flex
          align="center"
          direction="column"
          gap="$2"
          css={{ '@mobile': { flexDirection: 'row', justifyContent: 'space-between' } }}
          role="listitem"
          aria-label={t('helpPanelDescription')}
        >
          <Flex align="center" gap="$4">
            <QuestionMarkCircleIcon color="$linkDefault" />
            <FloraTypography
              fontSize="bodySmallStandard"
              css={{ color: '$nonInteractiveAuxiliar' }}
            >
              {t('helpPanelDescription')}
            </FloraTypography>
          </Flex>
          <FloraButton
            hierarchy="tertiary"
            size="small"
            has="iconLeft"
            icon={<LinkIcon />}
            onClick={redirectToHelp}
          >
            {t('readDocsDescription')}
          </FloraButton>
        </Flex>
      }
    />
  )
}

export { HelpPanel, MCM_FAQ_URL }
