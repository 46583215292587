import React from 'react'

import { Dropdown, DropdownItem, Text } from '@grupoboticario/flora-react'

import { useTranslation } from '@shared/i18n'

import { OrderStatusType } from '../orders.types'
import { Header } from './orders-header.styles'

interface OrdersHeaderProps {
  onStatusChange: (status: string) => void
  orderStatus: string
}

const OrdersHeader: React.FC<OrdersHeaderProps> = ({ onStatusChange, orderStatus }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'header' })

  return (
    <Header align="center" justify="space-between">
      <Text
        as="h3"
        color="$nonInteractivePredominant"
        size="bodyLargeStandardMedium"
        weight="medium"
      >
        {t('title')}
      </Text>

      <Dropdown
        id="dropdown-status"
        labelText={t('filter')}
        onChange={(e) => onStatusChange(e.target.value)}
        css={{ width: '100%', '@tablet': { width: '270px' } }}
        value={orderStatus ?? ''}
        multiple
      >
        <DropdownItem value={OrderStatusType.CANCELLED}>{t('canceled')}</DropdownItem>
        <DropdownItem value={OrderStatusType.SEPARATION}>{t('separation')}</DropdownItem>
        <DropdownItem value={OrderStatusType.TRANSPORT}>{t('transport')}</DropdownItem>
        <DropdownItem value={OrderStatusType.DELIVERED}>{t('delivered')}</DropdownItem>
      </Dropdown>
    </Header>
  )
}

export { OrdersHeader }
