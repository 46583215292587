import React from 'react'

import { Tag, Text } from '@grupoboticario/flora-react'
import {
  CalendarIcon,
  CardIcon,
  DollarSignCardIcon,
  TrayArrowDownIcon,
} from '@grupoboticario/flora-react-icons'

import { useTranslation } from '@shared/i18n'

import { OrderPayment } from '../../order-detail.types'
import { useOrderPayment, type PaymentTag } from '../payments.hooks'
import {
  DownloadButton,
  Identifier,
  PaymentContainer,
  PaymentInfoContainer,
  PaymentInfoLabel,
  PaymentTagsContainer,
} from './payment.styles'

interface Payment {
  payment: OrderPayment
  installments: number
}

const Payment: React.FC<Payment> = ({ payment, installments }) => {
  const pay = useOrderPayment(payment, installments)
  const { t } = useTranslation('orders', { keyPrefix: 'detail.payments' })

  return (
    <PaymentContainer>
      <PaymentTags tags={pay.getTags()} />
      <Identifier>{t('paymentNumber', { number: payment.identifier })}</Identifier>
      <PaymentInfo icon="calendar" label={t('dueDate')} value={pay.getDueDate()} />
      <PaymentInfo icon="card" label={t('paymentMethod')} value={pay.getMethod()} />
      <PaymentInfo icon="card" label={t('currentInstalment')} value={pay.getCurrentInstalment()} />
      <PaymentInfo icon="money" label={t('value')} value={pay.getValue()} />

      {pay.isBillet && (
        <DownloadButton
          isFull
          size="small"
          hierarchy="secondary"
          has="iconLeft"
          disabled={pay.isPaidout}
          onClick={pay.downloadBillet}
          icon={<TrayArrowDownIcon />}
        >
          {t('downloadBillet')}
        </DownloadButton>
      )}
    </PaymentContainer>
  )
}

interface PaymentTagsProps {
  tags: PaymentTag[]
}

const PaymentTags: React.FC<PaymentTagsProps> = ({ tags }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'detail.payments' })

  return (
    <PaymentTagsContainer>
      {tags.map((tag, i) => (
        <Tag key={i} shape="ghost" variant={tag.color} css={{ textTransform: 'uppercase' }}>
          {t(tag.text)}
        </Tag>
      ))}
    </PaymentTagsContainer>
  )
}

interface PaymentInfoProps {
  label: string
  value?: string
  icon: keyof typeof ICONS
}

const PaymentInfo: React.FC<PaymentInfoProps> = ({ value, label, icon }) => (
  <PaymentInfoContainer>
    <PaymentInfoLabel>
      {ICONS[icon]}

      <Text as="h6" color="$nonInteractiveAuxiliar" size="exceptionsAuxiliarMedium" weight="medium">
        {label}
      </Text>
    </PaymentInfoLabel>

    <Text as="p" color="$nonInteractivePredominant" size="bodyLargeShortMedium" weight="medium">
      {value}
    </Text>
  </PaymentInfoContainer>
)

const ICONS = {
  calendar: <CalendarIcon size="16px" color="$9" />,
  card: <CardIcon size="16px" color="$9" />,
  money: <DollarSignCardIcon size="16px" color="$9" />,
}

export { Payment }
