import { useEffect, useState } from 'react'

import { Text } from '@grupoboticario/flora-react'

interface RetryTimerProps {
  retryDelayMs: number
}

const RetryTimer = ({ retryDelayMs }: RetryTimerProps): JSX.Element => {
  const [timeLeft, setTimeLeft] = useState(retryDelayMs)

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((tl) => tl - 1000)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const seconds = Math.floor(timeLeft / 1000)
  const minutes = Math.floor(seconds / 60)
  const formattedSeconds = seconds % 60

  return (
    <Text color="$nonInteractiveAuxiliar" size="bodyLargeShortMedium">
      {`${minutes < 10 ? '0' : ''}${minutes}:${formattedSeconds < 10 ? '0' : ''}${formattedSeconds}`}
    </Text>
  )
}

export { RetryTimer, RetryTimerProps }
