import { Flex, styled, Text } from '@grupoboticario/flora-react'

const userInitialsSize = '24px'

const UserInitials = styled(Text, {
  position: 'absolute',
  top: '$2',
  left: '$2',
  display: 'inline-block',
  width: userInitialsSize,
  height: userInitialsSize,
  backgroundColor: '$nonInteractiveAltAuxiliar',
  borderRadius: '50%',
  color: '$nonInteractiveAltPredominant',
  fontSize: '$auxiliar',
  fontWeight: '$medium',
  lineHeight: userInitialsSize,
  textAlign: 'center',
})

const ListItem = styled(Flex, {
  position: 'relative',
  lineHeight: '$6',
  cursor: 'pointer',
  padding: '$2 $2 $2 $10',

  '&::before': {
    content: '',
    position: 'absolute',
    inset: '2px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderRadius: '6px',
    transitionProperty: 'border-color, background-color',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'ease-out',
  },

  '&:not(:last-of-type)': {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: '$nonInteractiveOutline',
  },

  '&:hover, &:focus, &:focus-visible': {
    '&::before': {
      borderColor: '$actionableDefault',
    },
  },
})

export { UserInitials, ListItem }
