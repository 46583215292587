import React from 'react'

import { Flex, Tab, TabList, Tabs } from '@grupoboticario/flora-react'
import { CalendarIcon, CheckCircleIcon, CrossCircleIcon } from '@grupoboticario/flora-react-icons'

import { EmptyState } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { type OrderPayment } from '../order-detail.types'
import { OrderDetailPayment } from './order-detail-payment'
import { useOrderPayments } from './order-detail-payments.hooks'
import { Carousel, CarouselItem, TabPanel } from './order-detail-payments.styles'

interface OrderDetailPaymentsProps {
  payments: OrderPayment[]
}

const OrderDetailPayments: React.FC<OrderDetailPaymentsProps> = ({ payments }) => {
  const { paidouts, pendings, installments, hasPending, hasPaidout, canceled, hasCanceled } =
    useOrderPayments(payments)

  const { t } = useTranslation('orderDetailPayments')

  const getDefaultTab = (): string | undefined => {
    if (hasPending) {
      return 'pending'
    }

    if (hasPaidout) {
      return 'done'
    }

    if (hasCanceled) {
      return 'canceled'
    }

    return 'empty'
  }

  return (
    <Tabs defaultValue={getDefaultTab()} orientation="horizontal">
      <TabList>
        <Tab disabled={!hasPending} icon={<CalendarIcon />} value="pending">
          {t('pending')}
        </Tab>

        <Tab disabled={!hasPaidout} icon={<CheckCircleIcon />} value="done">
          {t('done')}
        </Tab>

        <Tab disabled={!hasCanceled} icon={<CrossCircleIcon />} value="canceled">
          {t('canceled')}
        </Tab>
      </TabList>

      <TabPanel value="pending">
        <Carousel>
          {pendings.map((payment) => (
            <CarouselItem key={payment.identifier}>
              <OrderDetailPayment payment={payment} installments={installments} />
            </CarouselItem>
          ))}
        </Carousel>
      </TabPanel>

      <TabPanel value="done">
        <Carousel>
          {paidouts.map((payment) => (
            <CarouselItem key={payment.identifier}>
              <OrderDetailPayment payment={payment} installments={installments} />
            </CarouselItem>
          ))}
        </Carousel>
      </TabPanel>

      <TabPanel value="canceled">
        <Carousel>
          {canceled.map((payment) => (
            <CarouselItem key={payment.identifier}>
              <OrderDetailPayment payment={payment} installments={installments} />
            </CarouselItem>
          ))}
        </Carousel>
      </TabPanel>

      <TabPanel value="empty">
        <Flex justify="center">
          <EmptyState
            size="md"
            title={t('emptyState.title')}
            description={t('emptyState.subtitle')}
          />
        </Flex>
      </TabPanel>
    </Tabs>
  )
}

export { OrderDetailPayments }
