import React from 'react'

import { ColorThemeProps } from '@grupoboticario/flora'
import { Text } from '@grupoboticario/flora-react'
import { CheckCircleIcon, ClockIcon, CrossCircleIcon } from '@grupoboticario/flora-react-icons'

import {
  Arrow,
  LineArrowContainer,
  LineDashed,
  StatusContainer,
  StepItemContainer,
} from './historical-step.styles'

interface HistoricalStepProps {
  date?: string
  description: string
  status: 'done' | 'error' | 'await' | 'current'
}

const HistoricalStep: React.FC<HistoricalStepProps> = ({ status, description, date }) => {
  const itemStatus = statuses[status]

  return (
    <StepItemContainer>
      <StatusContainer>
        {itemStatus.icon}

        <Text
          color={itemStatus.textColor as ColorThemeProps}
          size="bodyLargeStandardMedium"
          weight="medium"
          isTruncated
        >
          {itemStatus.text}
        </Text>

        {(status === 'done' || status === 'error') && <LineArrow />}
        {(status === 'await' || status === 'current') && <LineDashed />}
      </StatusContainer>

      <Text size="bodySmallStandardMedium" color="$nonInteractiveAuxiliar" align="center">
        {description}
      </Text>

      {date && (
        <Text size="bodySmallStandardMedium" color="$nonInteractiveAuxiliar" align="center">
          {date}
        </Text>
      )}
    </StepItemContainer>
  )
}

const LineArrow = () => (
  <LineArrowContainer>
    <Arrow />
  </LineArrowContainer>
)

const statuses = {
  done: {
    textColor: '$nonInteractivePredominant',
    text: 'Concluido',
    icon: (
      <CheckCircleIcon
        size={24}
        color="$backgroundPrimary"
        css={{ backgroundColor: '$success-standard', borderRadius: '$pill' }}
      />
    ),
  },
  await: {
    textColor: '$nonInteractiveAuxiliar',
    text: 'Aguardando',
    icon: (
      <ClockIcon
        size={24}
        color="$backgroundPrimary"
        css={{ backgroundColor: '$nonInteractiveAltAuxiliar', borderRadius: '$pill' }}
      />
    ),
  },
  error: {
    textColor: '$nonInteractivePredominant',
    text: 'Pedido cancelado',
    icon: (
      <CrossCircleIcon
        size={24}
        color="$backgroundPrimary"
        css={{ backgroundColor: '$error-standard', borderRadius: '$pill' }}
      />
    ),
  },
  current: {
    textColor: '$actionableDefault',
    text: 'Em andamento',
    icon: (
      <ClockIcon
        size={24}
        color="$backgroundPrimary"
        css={{ backgroundColor: '$actionableDefault', borderRadius: '$pill' }}
      />
    ),
  },
}

export { HistoricalStep, HistoricalStepProps }
