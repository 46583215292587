import { Fragment, useCallback } from 'react'

import { toCurrency } from '@grupoboticario/vdi-mfe-utils'

import { useApiData } from '@shared/api'
import { DataBlock, DataBlockProps, DataSection, GridMobile } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { type ResellerCreditDTO } from '../../financial-data.types'
import { type ResselerCreditProps } from './reseller-credit.types'

const ResselerCredit = ({ resellerId }: ResselerCreditProps): JSX.Element => {
  const { t } = useTranslation('resellerCredit')

  const getApiParams = useCallback(() => ({ resellerId }), [resellerId])

  const { data, error, filled, loading, unavailable, refetch } = useApiData<ResellerCreditDTO>({
    dataSource: 'getResellerCredit',
    getApiParams,
    pollingOnError: true,
  })

  const getIncreasedInCycleDescription = (increased: boolean): DataBlockProps['description'] =>
    increased
      ? { text: t('dataBlock.increasedInCycle.yes'), status: 'success', capitalize: true }
      : { text: t('dataBlock.increasedInCycle.no'), status: 'error', capitalize: true }

  return (
    <DataSection
      title={t('title')}
      fallbackHeight={228}
      onRetry={refetch}
      unavailable={unavailable}
      loading={loading}
      filled={filled}
      error={error}
      data={data}
      render={(credit) => (
        <Fragment>
          <DataBlock
            title={t('actualCyclePurchase')}
            description={{
              text: data?.actualCyclePurchases
                ? toCurrency(data.actualCyclePurchases)
                : t('noActualCyclePurchase'),
              color: data?.actualCyclePurchases
                ? '$statusSuccessNonInteractiveEmphasis'
                : '$statusAlertNonInteractiveEmphasis',
            }}
          />

          <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
            <DataBlock
              title={t('availableCredit.title')}
              description={{ text: toCurrency(credit.availableCredit) }}
              tooltip={{ text: t('availableCredit.tooltip') }}
            />
            <DataBlock
              title={t('creditIncreasedInCycle')}
              description={getIncreasedInCycleDescription(credit.increasedInCycle)}
            />
          </GridMobile>

          <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
            <DataBlock
              title={t('totalCredit.title')}
              description={{ text: toCurrency(credit.totalCredit) }}
              tooltip={{ text: t('totalCredit.tooltip') }}
            />

            <DataBlock
              title={t('balanceCCR')}
              description={{ text: toCurrency(credit.balanceCCR) }}
            />
          </GridMobile>
        </Fragment>
      )}
    />
  )
}

export { ResselerCredit }
