import { type ResellerClub } from '@reseller-data/providers'
import { DataBlock } from '@shared/components'
import { useTranslation } from '@shared/i18n'

interface FidelityProps {
  club?: ResellerClub
}

const Fidelity = ({ club }: FidelityProps): JSX.Element => {
  const { t } = useTranslation('fidelity')

  const renderFidelityValue = club?.value ? club.value : t('withoutSegmentation')

  return <DataBlock title={t('title')} description={{ text: renderFidelityValue }} />
}

export { Fidelity, type FidelityProps }
