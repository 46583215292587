import { Box } from '@grupoboticario/flora-react-box'
import { Flex } from '@grupoboticario/flora-react-flex'
import { FloraTypography } from '@grupoboticario/flora-react-typography'
import { DataBlock } from '@shared/components'
import { useTranslation } from '@shared/i18n'

interface NextTierProgressProps {
  remainingValue: number
  totalValue: number
  name: string
}

const NextTierProgress: React.FC<NextTierProgressProps> = (props) => {
  const { t } = useTranslation('resellerFidelity')

  const { remainingValue, name, totalValue } = props

  const renderRemainingValue = (value: number) => {
    return `R$ ${value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`
  }

  const remainingPercentage = Math.floor(((totalValue - remainingValue) / totalValue) * 100)

  return (
    <DataBlock
      renderContentOnly
      content={
        <Flex direction="column" gap="$1" role="listitem" aria-label={t('nextTierProgressTitle')}>
          <Box
            css={{
              height: '$2',
              minHeight: '$2',
              width: '100%',
              borderRadius: '40px',
              overflow: 'hidden',
              background: '$disabledBackground !important',
            }}
          >
            <Box
              css={{
                height: '100%',
                width: `${remainingPercentage}%`,
                background: '$nonPrimaryButtonContent',
              }}
              role="progressbar"
              aria-label={t('nextTierProgressLabel', { progress: remainingPercentage, tier: name })}
            />
          </Box>
          <Flex direction="column">
            <FloraTypography
              fontSize="exceptionsRestricted"
              css={{ color: '$nonInteractiveAuxiliar' }}
            >
              {t('nextTierRemaining')}{' '}
              <b style={{ fontWeight: '700' }}>{t('nextTierRemainingValue', { tier: name })}</b>
            </FloraTypography>
            <FloraTypography
              fontSize="bodyLargeStandard"
              css={{ color: '$nonInteractivePredominant' }}
              fontWeight="medium"
            >
              {renderRemainingValue(remainingValue)}
            </FloraTypography>
          </Flex>
        </Flex>
      }
    />
  )
}

export { NextTierProgress, NextTierProgressProps }
