import { type IEventTracker } from '@vdi/event-tracker'

interface GA3Event {
  event?: 'event'
  action: string
  category: string
  label: string
}

type EventTracker = IEventTracker<Event, string>

interface GA4Event {
  event: 'analytics-event'
  event_parameters: Record<string, string | undefined>
}

type Event = GA4Event | GA3Event

interface EventBaseData {
  reId: string
  attendanceId: string
}

enum ActionType {
  clickButton = 'clique:botao',
  clickAccordion = 'clique:accordion',
  clickIcon = 'clique:icone',
  callbackSearch = 'callback:busca-atendimento',
  interactionModal = 'interacao:modal:{{name}}',
}

enum InteractionDetail {
  click = 'click',
  view = 'view',
}

enum TabName {
  profile = 'perfil-re',
  general = 'visao-geral',
  financial = 'financeiro',
}

enum EventName {
  interactionAttendance = 'interaction_atendimento',
  callbackSearchAttendance = 'callback_busca_atendimento',
}

enum CategoryType {
  base = 'vd-interativa:atendimento',
  profile = 'vd-interativa:atendimento:perfil-re',
  finanancial = 'vd-interativa:atendimento:financeiro',
  general = 'vd-interativa:atendimento:visao-geral',
  tabulation = 'vd-interativa:atendimento:tabulacao',
  promotional = 'vd-interativa:atendimento:promocoes',
}

export {
  CategoryType,
  ActionType,
  type EventBaseData,
  type GA4Event,
  type GA3Event,
  type Event,
  TabName,
  InteractionDetail,
  EventName,
  type EventTracker,
}
