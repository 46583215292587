import React, { useCallback, useEffect, useState } from 'react'

import { DataSection } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { Flex } from '@grupoboticario/flora-react'
import { toLocaleDate } from '@grupoboticario/vdi-mfe-utils'

import { useOrderDetailHistoricalContext } from '../order-detail.provider'
import { HistoricalStep, HistoricalStepProps } from './historical-step'
import { HistoricalContainer } from './historical.styles'
import { OrderHistorical } from './historical.types'
import { TrackingButton } from './tracking-button'

interface HistoricalProps {
  resellerId: string
  orderNumber: string
}

const Historical: React.FC<HistoricalProps> = ({ resellerId, orderNumber }) => {
  const [steps, setSteps] = useState<HistoricalStepProps[]>([])
  const { t } = useTranslation('orders', { keyPrefix: 'detail.historical' })
  const { data, filled, loading, error, refetch } = useOrderDetailHistoricalContext()

  const getSteps = useCallback(
    (historical: OrderHistorical[]) => {
      const historicalSteps: HistoricalStepProps[] = []

      for (let i = 0; i < initialHistorical.length; i++) {
        const history = historical[i]
        const historyStep = initialHistorical[i]
        const historyStepCurrent = { ...historyStep, description: t(historyStep.description) }

        if (history) {
          historyStepCurrent.status = history.status
          historyStepCurrent.description = history.description
          historyStepCurrent.date = history.endDate ? toLocaleDate(history.endDate) : ''
        }

        historicalSteps.push(historyStepCurrent)

        if (history?.status === 'error') {
          historyStepCurrent.description = t('orderCancelled')
          historyStepCurrent.date = toLocaleDate(history.startDate)
          break
        }
      }

      setSteps(historicalSteps)
    },
    [t],
  )

  useEffect(() => {
    if (filled) getSteps(data)
  }, [filled, data, getSteps])

  return (
    <DataSection
      data={data}
      error={error}
      filled={filled}
      loading={loading}
      onRetry={refetch}
      fallbackHeight={145}
      title={
        <Flex as="span" align="center" justify="space-between">
          {t('orderFollow')}
          <TrackingButton resellerId={resellerId} orderNumber={orderNumber} />
        </Flex>
      }
      render={() => (
        <HistoricalContainer>
          {steps.map((s, i) => (
            <HistoricalStep key={i} {...s} />
          ))}
        </HistoricalContainer>
      )}
    />
  )
}

const initialHistorical: HistoricalStepProps[] = [
  { status: 'await', description: 'orderPlaced' },
  { status: 'await', description: 'orderPaymentApproved' },
  { status: 'await', description: 'orderSeparation' },
  { status: 'await', description: 'orderInTransport' },
  { status: 'await', description: 'orderDelivered' },
]

export { Historical }
