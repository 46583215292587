import { OrderStatusTypes } from '../order-history.types'
import { StyledTag } from './order-status.styles'

interface OrderStatusProps {
  status: OrderStatusTypes
  description: string
}

const OrderStatus: React.FC<OrderStatusProps> = ({ status, description }) => (
  <StyledTag variant={statuses[status] ?? 'alert'} shape="ghost" size="medium">
    {description}
  </StyledTag>
)

const statuses: Record<string, 'success' | 'error'> = {
  [OrderStatusTypes.DELIVERED]: 'success',
  [OrderStatusTypes.CANCELLED]: 'error',
}

export { OrderStatus, type OrderStatusProps }
