import { Fragment, useCallback } from 'react'

import { toCurrency } from '@grupoboticario/vdi-mfe-utils'

import { useApiData } from '@shared/api'
import { DataBlock, DataSection, GridMobile } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { CurrentTier } from './current-tier'
import { HelpPanel } from './help-panel'
import { NextTierProgress } from './next-tier-progress'
import { PointPanel } from './point-panel'
import { type ResellerFidelityDTO } from './reseller-fidelity.types'

interface ResellerFidelityProps {
  resellerId: string
}

const ResellerFidelity = ({ resellerId }: ResellerFidelityProps): JSX.Element => {
  const { t } = useTranslation('resellerFidelity')
  const getApiParams = useCallback(() => ({ resellerId }), [resellerId])

  const { data, loading, error, filled, unavailable, refetch } = useApiData<ResellerFidelityDTO>({
    dataSource: 'getResellerFidelity',
    getApiParams,
    pollingOnError: true,
  })

  const getTierLabel = (label: string, tier: string): string => `${label} - ${tier}`

  const getTierValue = (nextTierValue: number): string => {
    if (nextTierValue <= 0) {
      return t('nextTierExceeded')
    }
    return toCurrency(nextTierValue)
  }

  const isMcm = data?.mcm

  const sectionTitle = t(isMcm ? 'mcmFidelitySectionTitle' : 'title')
  const sectionDescription = data?.mcm
    ? t('lastUpdatedAt', {
        date: new Date(data.mcm?.updatedAt).toLocaleDateString(),
        time: new Date(data.mcm?.updatedAt).toLocaleTimeString(),
      })
    : null

  return (
    <DataSection
      data-testid="profile-data-fidelity"
      title={sectionTitle}
      description={sectionDescription}
      fallbackHeight={228}
      onRetry={refetch}
      unavailable={unavailable}
      loading={loading}
      filled={filled}
      error={error}
      data={data}
      render={({ mcm, ...fidelity }) =>
        isMcm ? (
          <Fragment>
            <GridMobile gap="$1" templateColumns="4fr 4fr" mobileTemplateColumns="4fr">
              <CurrentTier
                value={mcm.tier.name}
                nextTierValue={mcm.nextTier.name}
                imageUrl={mcm.tier.iconUrl}
              />
              <NextTierProgress
                remainingValue={mcm.nextTier.remainingValue}
                totalValue={mcm.nextTier.totalValue}
                name={mcm.nextTier.name}
              />
            </GridMobile>
            <GridMobile gap="$1" templateColumns="4fr 4fr 4fr" mobileTemplateColumns="4fr">
              <PointPanel
                value={mcm.availablePoints}
                title={t('redeemablePointsTitle')}
                description={t('redeemablePointsDescription')}
              />
              <PointPanel
                value={mcm.releasePoints}
                title={t('releasingPointsTitle')}
                description={t('releasingPointsDescription')}
                prefix="+"
              />
              <PointPanel
                value={mcm.expiringPoints}
                title={t('expiringPointsTitle')}
                description={t('expiringPointsDescription')}
                prefix="-"
              />
            </GridMobile>
            <HelpPanel />
          </Fragment>
        ) : (
          <Fragment>
            <GridMobile gap="$1" templateColumns="4fr 4fr" mobileTemplateColumns="4fr">
              <DataBlock
                title={t('currentTier')}
                tooltip={{ text: t('tierChangingTooltip') }}
                description={{
                  text: getTierLabel(fidelity.currentTier.label, fidelity.currentTier.value),
                }}
              />
              <DataBlock
                title={t('nextTier')}
                description={{
                  text: getTierLabel(fidelity.nextTier.label, fidelity.nextTier.value),
                }}
              />
            </GridMobile>
            <DataBlock
              title={t('accumulatedValue')}
              description={{ text: toCurrency(fidelity.accumulatedValue) }}
            />
            <DataBlock
              title={t('valueToNextTier')}
              description={{ text: getTierValue(fidelity.valueNextTier) }}
            />
          </Fragment>
        )
      }
    />
  )
}

export { ResellerFidelity }
