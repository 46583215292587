import { useFeatureFlag } from 'configcat-react'

import { Flex } from '@grupoboticario/flora-react'

import { SPACE_BETWEEN_SECTION } from '../../reseller-data.styles'

import { ResellerBills } from './components/reseller-bills'
import { ResselerCredit } from './components/reseller-credit'

interface FinancialDataProps {
  resellerId: string
}

const FinancialData = ({ resellerId }: FinancialDataProps): JSX.Element => {
  const { value: showResellerBills } = useFeatureFlag('bills', false)

  return (
    <Flex direction="column" gap={SPACE_BETWEEN_SECTION} data-testid="financial-data">
      <ResselerCredit resellerId={resellerId} />
      {showResellerBills && <ResellerBills resellerId={resellerId} />}
    </Flex>
  )
}

export { FinancialData }
