import { Grid, Hidden } from '@grupoboticario/flora-react'

import { AttendanceList } from '@register-attendance/modules'
import { ResellerData } from '@reseller-data/reseller-data'
import { ResellerSearch } from '@reseller-search/reseller-search'

import { useIsAttending } from '@register-attendance/hooks'
import { useTranslation } from '@shared/i18n'
import {
  AppContainer,
  AttendanceListTitle,
  globalStyles,
  gridProps,
  leftColumnProps,
  rightColumnProps,
} from './app.styles'
import { GridColumn } from './grid-column'

const App = (): JSX.Element => {
  globalStyles()
  const { t } = useTranslation('attendanceList')

  const isAttending = useIsAttending()

  return (
    <AppContainer>
      <Grid {...gridProps}>
        <GridColumn showOnMobile={!isAttending} data-testid="left-column" {...leftColumnProps}>
          <Hidden from="tablet">
            <AttendanceListTitle level={6}>{t('attendances')}</AttendanceListTitle>
          </Hidden>
          <ResellerSearch />
          <AttendanceList />
        </GridColumn>

        <GridColumn showOnMobile={isAttending} data-testid="right-column" {...rightColumnProps}>
          <ResellerData />
        </GridColumn>
      </Grid>
    </AppContainer>
  )
}

export { App }
