import React from 'react'

import { Tag, Text } from '@grupoboticario/flora-react'

import {
  CalendarIcon,
  CardIcon,
  DollarSignCardIcon,
  TrayArrowDownIcon,
} from '@grupoboticario/flora-react-icons'

import { useTranslation } from '@shared/i18n'

import { type OrderPayment } from '../order-detail.types'
import { useOrderPayment, type PaymentTag } from './order-detail-payments.hooks'

import {
  DownloadButton,
  Identifier,
  OrderDetailPaymentContainer,
  PaymentInfoContainer,
  PaymentInfoLabel,
  PaymentTagsContainer,
} from './order-detail-payments.styles'

interface OrderDetailPaymentProps {
  payment: OrderPayment
  installments: number
}

const OrderDetailPayment: React.FC<OrderDetailPaymentProps> = ({ payment: pay, installments }) => {
  const payment = useOrderPayment(pay, installments)
  const { t } = useTranslation('orderDetailPayment')

  return (
    <OrderDetailPaymentContainer>
      <PaymentTags tags={payment.getTags()} />
      <Identifier>Nº {pay.identifier}</Identifier>
      <PaymentInfo icon="calendar" label={t('dueDate')} value={payment.getDueDate()} />
      <PaymentInfo icon="card" label={t('paymentMethod')} value={payment.getMethod()} />
      <PaymentInfo
        icon="card"
        label={t('currentInstalment')}
        value={payment.getCurrentInstalment()}
      />

      <PaymentInfo icon="money" label={t('value')} value={payment.getValue()} />

      {payment.isBillet && (
        <DownloadButton
          isFull
          size="small"
          hierarchy="secondary"
          has="iconLeft"
          disabled={payment.isPaidout}
          onClick={payment.downloadBillet}
          icon={<TrayArrowDownIcon />}
        >
          {t('downloadBillet')}
        </DownloadButton>
      )}
    </OrderDetailPaymentContainer>
  )
}

interface PaymentTagsProps {
  tags: PaymentTag[]
}

const PaymentTags: React.FC<PaymentTagsProps> = ({ tags }) => {
  const { t } = useTranslation('orderDetail')

  return (
    <PaymentTagsContainer>
      {tags.map((tag, i) => (
        <Tag key={i} shape="ghost" variant={tag.color} css={{ textTransform: 'uppercase' }}>
          {t(tag.text)}
        </Tag>
      ))}
    </PaymentTagsContainer>
  )
}

interface PaymentInfoProps {
  label: string
  value?: string
  icon: keyof typeof ICONS
}

const PaymentInfo: React.FC<PaymentInfoProps> = ({ value, label, icon }) => (
  <PaymentInfoContainer>
    <PaymentInfoLabel>
      {ICONS[icon]}

      <Text as="h6" color="$nonInteractiveAuxiliar" size="exceptionsAuxiliarMedium" weight="medium">
        {label}
      </Text>
    </PaymentInfoLabel>

    <Text as="p" color="$nonInteractivePredominant" size="bodyLargeShortMedium" weight="medium">
      {value}
    </Text>
  </PaymentInfoContainer>
)

const ICONS = {
  calendar: <CalendarIcon size="16px" color="$9" />,
  card: <CardIcon size="16px" color="$9" />,
  money: <DollarSignCardIcon size="16px" color="$9" />,
}

export { OrderDetailPayment }
