import { CanCopyButton } from '@shared/components'

import { getCapitalizedText } from '@shared/utils/get-capitalized-text'

import { Description } from './data-block-description.styles'
import { type DataBlockDescriptionProps } from './data-block-description.types'

const DataBlockDescription = ({
  capitalize,
  color,
  status,
  text,
  textToCopy,
  canCopy,
  avatar,
  sectionName,
  textName,
  tabName,
}: DataBlockDescriptionProps): JSX.Element => {
  const capitalizedText = getCapitalizedText(text, capitalize)

  const descriptionStyle = {
    color: color ?? '$nonInteractivePredominant',
  }

  const renderCanCopy = canCopy && text && (
    <CanCopyButton
      textToCopy={textToCopy}
      clipboardText={capitalizedText}
      textName={textName}
      sectionName={sectionName}
      tabName={tabName}
    />
  )

  return (
    <Description as="p" hasAvatar={avatar} status={status} css={descriptionStyle}>
      {capitalizedText}
      {renderCanCopy}
    </Description>
  )
}

export { DataBlockDescription, type DataBlockDescriptionProps }
