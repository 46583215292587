import { DataBlock, DataSection, List } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { ResellerBillsItem } from './reseller-bills-item'
import { useResellerTitles } from './reseller-bills.hooks'

interface ResellerBillsProps {
  resellerId: string
}

const ResellerBills: React.FC<ResellerBillsProps> = ({ resellerId }) => {
  const { t } = useTranslation('resellerBills')
  const { data, error, filled, loading, limit, unavailable, refetch, onPaginate } =
    useResellerTitles(resellerId)

  return (
    <DataSection
      title={t('title')}
      fallbackHeight={403}
      onRetry={refetch}
      unavailable={unavailable}
      loading={loading}
      filled={filled}
      error={error}
      data={data}
      render={(history) => (
        <DataBlock
          data-testid="profile-data-orders"
          title={t('paymentTitles')}
          content={
            <List
              type="accordion"
              data={history.paymentTitles}
              renderItem={(title, index) => <ResellerBillsItem key={index} title={title} />}
              pagination={{
                page: history.pagination.currentPage,
                total: history.pagination.totalItems,
                itemsPerPage: limit,
                onPaginate,
              }}
            />
          }
        />
      )}
    />
  )
}

export { ResellerBills }
