import { type PaginationData } from '@shared/types'

enum OrderStatusTypes {
  DELIVERED = 'ENTREGUE',
  CANCELLED = 'CANCELADO',
}

interface ResellerOrder {
  number: string
  date: string
  status: OrderStatusTypes
  statusDescription: string
  totalValue: number
  cycle: string
}

interface ResellerOrderHistoryDTO {
  orders: ResellerOrder[]
  pagination: PaginationData
}

export { OrderStatusTypes, type ResellerOrder, type ResellerOrderHistoryDTO }
