import { Fragment } from 'react'

import { Grid } from '@grupoboticario/flora-react'

import { DataBlock, DataSection, GridMobile } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { useResellerDataView } from '../../../hooks'
import { ParentCode } from '../general-info/parent-code/parent-code'

interface AllocationInfoProps {
  cs?: string
}

const Allocation = ({ cs }: AllocationInfoProps): JSX.Element => {
  const { model, getData, error, filled, unavailable, loading } = useResellerDataView()
  const { t } = useTranslation('allocation')

  return (
    <DataSection
      unavailable={unavailable}
      loading={loading}
      error={error}
      filled={filled}
      data={model}
      title={t('title')}
      fallbackHeight={300}
      onRetry={getData}
      render={(data) => (
        <Fragment>
          <DataBlock title={t('dataBlock.cs')} description={{ text: cs }} />

          <Grid templateColumns="4fr 4fr" gap="$1">
            <ParentCode />
            <DataBlock
              title={t('dataBlock.allocation')}
              description={{ text: data.allocationRegion, capitalize: true }}
            />
          </Grid>

          <DataBlock title={t('dataBlock.storeName')} description={{ text: data.descStore }} />

          <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
            <DataBlock title={t('dataBlock.codeSector')} description={{ text: data.cdSector }} />
            <DataBlock title={t('dataBlock.sectorName')} description={{ text: data.descSector }} />
          </GridMobile>
          <DataBlock
            title={t('dataBlock.sectorResponsibleName')}
            description={{ text: data.sectorResponsible }}
          />
        </Fragment>
      )}
    />
  )
}

export { Allocation }
