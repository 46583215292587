import { Flex, Spinner, Text } from '@grupoboticario/flora-react'

interface LoadingStateProps {
  isLoading?: boolean
  title?: string
  description?: string
  containerHeight?: string
  containerWidth?: string
}

const LoadingState = ({
  containerWidth,
  containerHeight,
  description,
  isLoading = true,
  title,
}: LoadingStateProps): JSX.Element | null => {
  const renderTitle = title && (
    <Text as="h5" color="$nonInteractivePredominant" size="subTitleDeskMedium" weight="medium">
      {title}
    </Text>
  )

  const renderDescription = description && (
    <Text as="p" color="$nonInteractiveAuxiliar" size="bodySmallShortMedium" weight="medium">
      {description}
    </Text>
  )

  if (!isLoading) {
    return null
  }

  return (
    <Flex
      align="center"
      direction="column"
      justify="center"
      gap="$2"
      data-testid="loading"
      css={{ width: containerWidth ?? '100%', height: containerHeight ?? 'auto' }}
    >
      {renderTitle}
      {renderDescription}
      <Spinner color="$actionableDefault" size="md" />
    </Flex>
  )
}

export { LoadingState, type LoadingStateProps }
