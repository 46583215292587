import { useCallback, useState } from 'react'

import { useApiData } from '@shared/api'
import { DataBlock, DataSection, List } from '@shared/components'
import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { OrderHistoryItem } from './order-history-item'
import { type ResellerOrderHistoryDTO } from './order-history.types'

const ITEMS_PER_PAGE = 5

interface OrderHistoryProps {
  resellerId: string
}

const OrderHistory = ({ resellerId }: OrderHistoryProps): JSX.Element => {
  const { t } = useTranslation('orderHistory')
  const [page, setPage] = useState<number>(1)
  const events = useEvents()

  const getApiParams = useCallback(
    () => ({ resellerId, page, limit: ITEMS_PER_PAGE }),
    [resellerId, page],
  )

  const { data, loading, error, refetch, unavailable, filled } =
    useApiData<ResellerOrderHistoryDTO>({
      dataSource: 'getResellerOrderHistory',
      getApiParams,
      pollingOnError: true,
    })

  const handlePageChanged = (page: number): void => {
    events.clickOrderPagination(page)
    setPage(page)
  }

  return (
    <DataSection
      data-testid="profile-data-orders"
      title={t('title')}
      fallbackHeight={403}
      onRetry={refetch}
      unavailable={unavailable}
      loading={loading}
      filled={filled}
      empty={filled && !data.orders.length}
      emptyTitle={t('emptyState.title')}
      emptyDescription={t('emptyState.description')}
      error={error}
      data={data}
      render={(history) => (
        <DataBlock
          title={t('orders')}
          content={
            <List
              type="accordion"
              data={history.orders}
              renderItem={(order, index) => (
                <OrderHistoryItem key={index} order={order} resellerId={resellerId} />
              )}
              pagination={{
                itemsPerPage: ITEMS_PER_PAGE,
                total: history.pagination.totalItems,
                onPaginate: handlePageChanged,
                page,
              }}
            />
          }
        />
      )}
    />
  )
}

export { OrderHistory }
