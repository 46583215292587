import { ResellerMLDLink } from '@reseller-data/providers'
import { useApiData } from '@shared/api'
import { DataBlock } from '@shared/components'
import { TabName } from '@shared/events'
import { useTranslation } from '@shared/i18n'
import { useCallback } from 'react'

interface MLDLinkProps {
  resellerId: string
}

const MLDLink = ({ resellerId }: MLDLinkProps): JSX.Element => {
  const { t } = useTranslation(['generalInfo'])
  const getApiParams = useCallback(() => ({ resellerId }), [resellerId])

  const { data } = useApiData<ResellerMLDLink>({
    dataSource: 'getMLDLink',
    pollingOnError: true,
    getApiParams,
  })

  const mldLink = data?.mldLink

  return (
    <DataBlock
      sectionName="gerais"
      tabName={TabName.general}
      title={t('dataBlock.mld')}
      description={{ text: mldLink }}
      textToCopy={`${mldLink}?utm_medium=VDI_tela_atendimento&utm_source=VDI`}
      canCopy={Boolean(mldLink)}
    />
  )
}

export { MLDLink }
