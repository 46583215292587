import { useCurrentAttendanceContext } from '@register-attendance/providers'
import { GtmTracker } from '@vdi/event-tracker'

import { createAttendanceEvents, type AttendanceEvents } from './events.attendance'
import { createBaseEvents, type BaseEvents } from './events.base'
import { createFinancialEvents, type FinacialEvents } from './events.financial'
import { createGeneralEvents, type GeneralEvents } from './events.general'
import { createProfileEvents, type ProfileEvents } from './events.profile'
import { createPromotionEvents, type PromotionEvents } from './events.promotions'
import { type EventTracker } from './events.types'

type UseEvents = BaseEvents &
  AttendanceEvents &
  FinacialEvents &
  ProfileEvents &
  GeneralEvents &
  PromotionEvents

const useEvents = (): UseEvents => {
  const { currentAttendance } = useCurrentAttendanceContext()
  const attendance = {
    attendanceId: currentAttendance?.attendanceId ?? '',
    reId: currentAttendance?.resellerId ?? '',
  }

  const tracker = GtmTracker as unknown as EventTracker

  return {
    ...createBaseEvents(tracker, attendance),
    ...createAttendanceEvents(tracker, attendance),
    ...createProfileEvents(tracker, attendance),
    ...createFinancialEvents(tracker, attendance),
    ...createGeneralEvents(tracker, attendance),
    ...createPromotionEvents(tracker, attendance),
  }
}

export { useEvents, type UseEvents }
