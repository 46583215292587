import React, { ReactNode } from 'react'

import { Flex, Tag, TagProps, Text, TextProps } from '@grupoboticario/flora-react'
import { CardHeaderLeft } from './card-header.styles'

interface CardHeaderProps {
  title: ReactNode
  size?: TextProps['size']
  tags?: {
    variant: TagProps['variant']
    label: string
  }[]
  actions?: ReactNode
}

const CardHeader: React.FC<CardHeaderProps> = ({ title, size, tags, actions }) => (
  <Flex gap="$2" align="center">
    <CardHeaderLeft>
      <Text size={size ?? 'bodySmallShortMedium'} weight="medium">
        {title}
      </Text>

      {tags?.map((tag, i) => (
        <Tag key={i} variant={tag.variant} shape="ghost" size="small">
          {tag.label}
        </Tag>
      ))}
    </CardHeaderLeft>

    {actions && <Flex gap="$1">{actions}</Flex>}
  </Flex>
)

export { CardHeader }
