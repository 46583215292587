import React, { Fragment } from 'react'

import { Table, TitleThead } from '@shared/components'
import { useIsMobile } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'

import { OrderProduct } from '../../order-detail.types'
import { ProductItem } from './product-item'

interface ProductListProps {
  products: OrderProduct[]
  title: string
}

const ProductList: React.FC<ProductListProps> = ({ products, title }) => {
  const { t } = useTranslation('orders', { keyPrefix: 'detail.products' })
  const isMobile = useIsMobile()

  return (
    <Table css={{ padding: '0px' }}>
      <thead>
        <tr>
          <th scope="col">{title}</th>
          {!isMobile && (
            <Fragment>
              <th scope="col">
                <TitleThead hidden>{t('quantity')}</TitleThead>
              </th>

              <th scope="col">
                <TitleThead hidden>{t('value')}</TitleThead>
              </th>
            </Fragment>
          )}
        </tr>
      </thead>

      <tbody>
        {products.map((p) => (
          <ProductItem key={p.code} product={p} />
        ))}
      </tbody>
    </Table>
  )
}

export { ProductItem, ProductList }
