import { styled, Text } from '@grupoboticario/flora-react'

const renderDescriptionStatus = (status: string): object => {
  const backgroundColor =
    status === 'success'
      ? '$statusSuccessNonInteractiveEmphasis'
      : '$statusErrorNonInteractiveEmphasis'

  return {
    paddingLeft: '$3',

    '&::before': {
      content: '',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '$1',
      height: '$6',
      borderRadius: '$medium',
      backgroundColor,
    },
  }
}

const Description = styled(Text, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '1px',
  width: '100%',
  minHeight: '$6',
  fontWeight: '$medium',
  lineHeight: '$normal',
  marginBottom: 0,
  overflowWrap: 'anywhere',
  zIndex: '$docked',

  variants: {
    hasAvatar: {
      true: {
        fontSize: '$subtitleDesk',
      },
      false: {
        fontSize: '$bodyLarge',
      },
    },
    status: {
      error: {
        ...renderDescriptionStatus('error'),
      },

      success: {
        ...renderDescriptionStatus('success'),
      },
    },
  },
})

export { Description }
