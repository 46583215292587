import { Text } from '@grupoboticario/flora-react'

import {
  CalendarIcon,
  DollarSignCircleIcon,
  ShoppingBagIcon,
} from '@grupoboticario/flora-react-icons'

import { toCurrency, toLocaleDate } from '@grupoboticario/vdi-mfe-utils'

import { AccordionItem } from '@shared/components'
import { useEvents } from '@shared/events'
import { useDrawer, useIsMobile } from '@shared/hooks'
import { useTranslation } from '@shared/i18n'

import { OrderDetail } from '../order-detail'
import { type ResellerOrder } from '../order-history.types'
import { OrderStatus } from '../order-status'

import { Button, ItemContainer } from './order-history-item.styles'

interface OrderHistoryItemProps {
  order: ResellerOrder
  resellerId: string
}

const OrderHistoryItem = ({ order, resellerId }: OrderHistoryItemProps): JSX.Element => {
  const { t } = useTranslation('orderHistoryItem')
  const isMobile = useIsMobile()
  const events = useEvents()
  let orderExpanded = false

  const { open } = useDrawer({
    title: isMobile ? t('orderDetails') : t('orderDetail', { orderNumber: order.number }),
    content: <OrderDetail resellerId={resellerId} orderNumber={order.number} />,
  })

  const onExpandOrder = (): void => {
    if (orderExpanded) {
      orderExpanded = false
    } else {
      orderExpanded = true
      events.clickOrderAccordion(order.number, order.statusDescription)
    }
  }

  const onOpenDetail = (): void => {
    open()
    events.clickOrderDetail(order.number)
  }

  const textSize = 'bodyLargeStandardMedium'
  const textWeight = 'medium'
  const textColor = '$nonInteractivePredominant'
  const iconColor = '$9'
  const iconSize = '16px'

  const accordionItemContent = (
    <ItemContainer>
      <div>
        <ShoppingBagIcon color={iconColor} size={iconSize} />
        <Text color={textColor} size={textSize} weight={textWeight}>
          {t('cycleNumber', { cycleNumber: order.cycle })}
        </Text>
      </div>

      <div>
        <CalendarIcon color={iconColor} size={iconSize} />
        <Text color={textColor} size={textSize} weight={textWeight}>
          {toLocaleDate(order.date)}
        </Text>
      </div>

      <div>
        <DollarSignCircleIcon color={iconColor} size={iconSize} />
        <Text color={textColor} size={textSize} weight={textWeight}>
          {toCurrency(order.totalValue)}
        </Text>
      </div>

      <Button hierarchy="secondary" size="small" onClick={onOpenDetail}>
        {t('viewDetails')}
      </Button>
    </ItemContainer>
  )

  return (
    <AccordionItem
      onClick={onExpandOrder}
      title={t('title', { orderNumber: order.number })}
      titleExtra={<OrderStatus status={order.status} description={order.statusDescription} />}
      content={accordionItemContent}
    />
  )
}

export { OrderHistoryItem }
