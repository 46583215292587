import { useCallback } from 'react'

import { Text } from '@grupoboticario/flora-react'

import { useApiData } from '@shared/api'
import { DataBlock, ErrorState, List, LoadingState, UnavailableState } from '@shared/components'
import { env } from '@shared/env'
import { useTranslation } from '@shared/i18n'
import { getCapitalizedText } from '@shared/utils'

import { ListItem, TopProductsContent } from './top-purchased-products.styles'
import { type ResellerTopProductDTO } from './top-purchased-products.types'

interface TopPurchasedProductsProps {
  resellerId: string
}

const TopPurchasedProducts: React.FC<TopPurchasedProductsProps> = ({ resellerId }) => {
  const { t } = useTranslation('topPurchasedProducts')
  const getApiParams = useCallback(() => ({ resellerId }), [resellerId])

  const { data, filled, loading, error, unavailable, refetch } = useApiData<ResellerTopProductDTO>({
    dataSource: 'getTopPurchasedProducts',
    getApiParams,
    pollingOnError: true,
  })

  const errorState = error && <ErrorState onButtonClick={refetch} />

  const loadintState = loading && <LoadingState />

  const emptyState = filled && data.length <= 0 && (
    <Text color="$statusAlertNonInteractiveEmphasis">{t('hasNoProducts')}</Text>
  )

  const unavailableState = unavailable && (
    <UnavailableState retryDelayMs={env.ERROR_POLLING_TIMEOUT} />
  )

  return (
    <DataBlock
      data-testid="top-purchased-products"
      title={t('title')}
      content={
        <TopProductsContent>
          {errorState}
          {loadintState}
          {unavailableState}
          {emptyState}
          {filled && (
            <List
              data={data}
              type="collapse"
              collapseItemsCount={5}
              collapseLessLabel={t('showLessProducts')}
              collapseMoreLabel={t('showMoreProducts')}
              renderItem={(item, index) => (
                <ListItem as="p" key={index}>
                  {getCapitalizedText(item.productDescription, true)}

                  <Text
                    as="small"
                    size="exceptionsRestrictedMedium"
                    color="$nonInteractiveAuxiliar"
                  >
                    {item.productCode}
                  </Text>
                </ListItem>
              )}
            />
          )}
        </TopProductsContent>
      }
    />
  )
}

export { TopPurchasedProducts }
