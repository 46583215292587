import { useFeatureFlag } from 'configcat-react'
import { Fragment, useCallback, useMemo } from 'react'

import { Flex } from '@grupoboticario/flora-react'
import { toCurrency, toLocaleDate } from '@grupoboticario/vdi-mfe-utils'

import { useApiData } from '@shared/api'
import {
  CanCopyButton,
  DataBlock,
  DataSection,
  ErrorState,
  GridMobile,
  LoadingState,
  Table,
  TitleThead,
  UnavailableState,
} from '@shared/components'
import { env } from '@shared/env'
import { TabName, useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { useIsMobile } from '@shared/hooks'
import { OrderStatus } from '../order-status'
import { OrderDetailListItem } from './order-detail-list-item'
import { OrderDetailPayments } from './order-detail-payments'
import { type OrderDetailDTO } from './order-detail.types'

interface OrderDetailProps {
  resellerId: string
  orderNumber: string
}

const OrderDetail = ({ resellerId, orderNumber }: OrderDetailProps): JSX.Element => {
  const { t } = useTranslation('orderDetail')
  const { value: showOrderPaymentsFlag } = useFeatureFlag('orderPayments', false)
  const events = useEvents()
  const isMobile = useIsMobile()

  const showOrderPayments = showOrderPaymentsFlag && !isMobile

  const getApiParams = useCallback(() => ({ orderNumber, resellerId }), [orderNumber, resellerId])

  const { loading, data, error, filled, unavailable, refetch } = useApiData<OrderDetailDTO>({
    dataSource: 'getResellerOrderDetail',
    getApiParams,
    pollingOnError: true,
  })

  const clipboardContent = useMemo(() => {
    if (!data?.items) return ''
    const list = data.items
    let clipboardString = `${t('orderComposition')}\n`

    for (let index = 0; index < list.length; index++) {
      const quantity = `[Qtd: ${list[index].quantity}]`
      const renderPrice =
        typeof list[index].totalValue === 'number'
          ? `(${t('price', { price: list[index].totalValue as number })})`
          : ''
      const renderBreakLine = list.length === index + 1 ? '' : '\n'

      clipboardString += `${index + 1}. ${
        list[index].name
      } ${quantity}${renderPrice}${renderBreakLine}`
    }

    return clipboardString
  }, [data, t])

  const handleCopy = (): void => {
    events.clickOrderCopyComposition(orderNumber)
  }

  return (
    <Flex css={{ width: 820 }} gap="$6" direction="column" align="stretch">
      {loading && (
        <LoadingState
          title={t('loadingState.title')}
          description={t('loadingState.subtitle')}
          containerHeight="calc(100vh - $16)"
        />
      )}

      {error && <ErrorState onButtonClick={refetch} />}

      {unavailable && <UnavailableState retryDelayMs={env.ERROR_POLLING_TIMEOUT} />}

      {filled && (
        <Fragment>
          <DataSection
            filled
            data={data}
            title={
              <Flex gap="$3">
                {t('orderNumber', { orderNumber: data.number })}
                <OrderStatus status={data.status} description={data.statusDescription} />
              </Flex>
            }
            render={() => (
              <Fragment>
                <GridMobile templateColumns="4fr 4fr" mobileTemplateColumns="4fr" gap="$1">
                  <DataBlock
                    title={t('dataBlock.shoppingCycle')}
                    description={{ text: data.cycle }}
                  />

                  <DataBlock
                    title={t('dataBlock.netValue')}
                    description={{ text: toCurrency(data.totalValue) }}
                  />

                  <DataBlock
                    title={t('dataBlock.shoppingDate')}
                    description={{ text: toLocaleDate(data.date) }}
                  />

                  <DataBlock
                    title={t('dataBlock.shoppingStatus')}
                    description={{ text: data.statusDescription }}
                  />
                </GridMobile>
              </Fragment>
            )}
          />

          {showOrderPayments && (
            <DataSection
              filled
              data={null}
              data-testid="order-detail:payments"
              title={t('payments')}
              render={() => <OrderDetailPayments payments={data.payments} />}
            />
          )}

          <DataSection
            filled
            data={data}
            title={
              <Flex as="span">
                {t('orderComposition')}
                <CanCopyButton
                  clipboardText={clipboardContent}
                  sectionName="detalhe-pedido"
                  tabName={TabName.profile}
                  textName={t('orderComposition')}
                  onCopy={handleCopy}
                />
              </Flex>
            }
            render={() => (
              <Table
                css={{
                  padding: '0px',
                }}
              >
                <thead>
                  <tr>
                    <th scope="col">{t('purchaseProducts')}</th>
                    {!isMobile && (
                      <Fragment>
                        <th scope="col">
                          <TitleThead hidden>{t('quantity')}</TitleThead>
                        </th>

                        <th scope="col">
                          <TitleThead hidden>{t('value')}</TitleThead>
                        </th>
                      </Fragment>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {data.items.map(
                    ({ name, code, quantity, totalValue }): JSX.Element => (
                      <OrderDetailListItem
                        key={code}
                        code={code}
                        name={name}
                        quantity={quantity}
                        totalValue={totalValue}
                      />
                    ),
                  )}
                </tbody>
              </Table>
            )}
          />
        </Fragment>
      )}
    </Flex>
  )
}

export { OrderDetail }
