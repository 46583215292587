import React from 'react'

import { Flex } from '@grupoboticario/flora-react'

import { DataBlock, DataSection } from '@shared/components'
import { useTranslation } from '@shared/i18n'

import { useOrderDetailHistoricalContext } from '../order-detail.provider'

const AdditionalInfo: React.FC = () => {
  const { data, filled, loading } = useOrderDetailHistoricalContext()
  const { t } = useTranslation('orders', { keyPrefix: 'detail.additionalInfo' })

  return (
    <DataSection
      data={data}
      filled={filled}
      fallbackHeight={72}
      loading={loading}
      title={t('orderAdditionalInfo')}
      render={(historical) => (
        <Flex wrap="wrap" gap="$2">
          <DataBlock title={t('finishedBy')} description={{ text: historical[0]?.userName }} />
        </Flex>
      )}
    />
  )
}

export { AdditionalInfo }
