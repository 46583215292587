import { Flex, FloraButton, Text } from '@grupoboticario/flora-react'

import { useEvents } from '@shared/events'
import { useTranslation } from '@shared/i18n'

import { Container, Title } from './error-state.styles'
import { ErrorStateProps } from './error-state.types'

const ErrorState = ({
  icon,
  buttonText,
  description,
  onButtonClick,
  title,
  children,
}: ErrorStateProps): JSX.Element => {
  const { t } = useTranslation('errorState')
  const events = useEvents()
  const descriptionText = description ?? t('description')

  const handleRetry = (): void => {
    events.clickRetry('section', descriptionText)
    onButtonClick?.()
  }

  const button = onButtonClick && (
    <FloraButton hierarchy="secondary" onClick={handleRetry}>
      {buttonText ?? t('buttonText')}
    </FloraButton>
  )

  return (
    <Container data-testid="error-state" align="center" justify="center" direction="column">
      <Flex direction="column" gap="$4" align="center" justify="center">
        {icon}

        <Flex
          direction="column"
          gap="$2"
          align="center"
          justify="center"
          css={{ textAlign: 'center' }}
        >
          <Title as="h5">{title ?? t('title')}</Title>

          <Text as="p" align="center" color="$nonInteractiveAuxiliar" size="bodySmallShortMedium">
            {descriptionText}
          </Text>
        </Flex>

        {children}
        {button}
      </Flex>
    </Container>
  )
}

export { ErrorState, type ErrorStateProps }
