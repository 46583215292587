import { Container, globalCss, Heading, styled } from '@grupoboticario/flora-react'
import { scrollbar } from '@shared/components'

const globalStyles = globalCss({
  'html, body, #root': {
    minHeight: '100vh',
  },
})

const appHeight = '100vh'
const mobileMenuHeight = '64px'

const AppContainer = styled(Container, {
  maxWidth: '100%',
  minHeight: appHeight,
  backgroundColor: '$backgroundSecondary',
  padding: '$0',
  overflow: 'hidden',

  '> *': {
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',

    '&::before, &::after': {
      boxSizing: 'inherit',
    },
  },

  'a, button, h1, h2, h3, h4, h5, h6, label, p, span': {
    fontSmooth: 'auto',
    '-webkit-font-smoothing': 'subpixel-antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    margin: 0,
  },
})

const AttendanceListTitle = styled(Heading, {
  padding: '$4 $2 0 $2',
})

const gridProps = {
  templateRows: '100%',
  templateColumns: '4fr 8fr',
  css: {
    maxHeight: `calc(100vh - ${mobileMenuHeight})`,
    position: 'absolute',
    overflow: 'hidden',
    top: mobileMenuHeight,
    bottom: 0,

    '@mobile': {
      maxHeight: 'initial',
      position: 'initial',
      overflow: 'initial',

      minHeight: appHeight,
    },
  },
}

const leftColumnProps = {
  css: {
    flexDirection: 'column',
    maxHeight: `calc(100vh - ${mobileMenuHeight})`,
    width: '100vw',
    padding: '$2',

    '@mobile': {
      borderRight: '1px solid $nonInteractiveOutline',
      maxHeight: 'initial',
      width: 'initial',
      display: 'flex',
      padding: 'initial',
    },
  },
}

const rightColumnProps = {
  css: {
    padding: '$0',
    overflow: 'hidden',
    flexDirection: 'column',
    ...scrollbar,
    maxHeight: `calc(100vh - ${mobileMenuHeight})`,
    width: '100vw',

    '@mobile': {
      height: appHeight,
      padding: '$4',
      overflow: 'auto',
      maxHeight: 'initial',
      width: 'initial',
      display: 'flex',
    },
  },
}

export {
  AppContainer,
  appHeight,
  AttendanceListTitle,
  globalStyles,
  gridProps,
  leftColumnProps,
  mobileMenuHeight,
  rightColumnProps,
}
