import { FloraButton, styled } from '@grupoboticario/flora-react'

const ItemContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridRowGap: '$7',

  '@tablet': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridColumnGap: '$4',
    gridRowGap: '$4',
  },

  '@desktop': {
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
  },

  div: {
    display: 'flex',
    alignItems: 'center',
    gap: '$2',
  },
})

const Button = styled(FloraButton, {
  gridColumn: '1 / 3',

  '@tablet': {
    gridColumn: '3 / 5',
  },

  '@desktop': {
    gridColumn: 'unset',
  },
})

export { Button, ItemContainer }
